
import Vue from 'vue';
import { nanoid } from 'nanoid';

import Icon from 'UI/Icon.vue';
import { composedPath } from 'Lib/utils/eventPathChecker';
import Search from 'Control/Search.vue';
import InternalUserResponse from 'Entities/userLoginHistory/InternalUserResponse';
import { SET_LAST_ROUTE } from 'Store/v2/Addresses';

interface Props {
    elements: string[];
    isBlockchains?: boolean;
    isAddresses?: boolean;
    isRequisites?: boolean;
    isPlacements?: boolean;
    title?: string;
    addNewAddressHandler?: any;
    addNewRequisiteHandler?: any;
    addressAliases?: string[];
    isEmpty?: boolean;
    hasError?: boolean;
    activeItemIndex?: number;
    assetsGroups?: { symbol: string; type: 'crypto-spot' | 'crypto-stable-coin' | 'fiat'; }[];
    disabledElementsIndexes?: number[];
    allowToSelectDisabledItems?: boolean;
    needIcon?: boolean;
    needSearch?: boolean;
    isSmallDropdown?: boolean;
    defaultItemText?: string;
}

interface Data {
    showDropdown: boolean;
    uniqueId: null | string;
    assetsGroupsNames: Record<string, string>;
    searchValue: string;
    firstGroupLoaded: boolean;
}

interface Methods {
    setActiveElement: (data: number) => void;
    shortString: (data: string) => string;
    setGroupedElement: (data: string) => void;
    goToRequisitesManagement: () => void;
}

interface Computed {
    groupedElements: { type: string, assets: string[] }[];
    filteredElements: string[];
    currentUser: InternalUserResponse | null;
    validBeneficiaryNames: string[];
}

export default Vue.extend<Data, Methods, Computed, Props>({
    components: { Search, Icon },
    props: {
        elements: {
            type: Array,
            default: () => [],
        },
        isBlockchains: {
            type: Boolean,
            default: false,
        },
        isAddresses: {
            type: Boolean,
            default: false,
        },
        isRequisites: {
            type: Boolean,
            default: false,
        },
        isPlacements: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: '',
        },
        addNewAddressHandler: {
            type: Function,
            default: () => true,
        },
        addNewRequisiteHandler: {
            type: Function,
            default: () => true,
        },
        addressAliases: {
            type: Array,
            default: () => [],
        },
        isEmpty: {
            type: Boolean,
            default: false,
        },
        hasError: {
            type: Boolean,
            default: false,
        },
        activeItemIndex: {
            type: Number,
            required: false,
        },
        assetsGroups: {
            type: undefined,
            default: () => [],
        },
        disabledElementsIndexes: {
            type: undefined,
            default: () => [],
        },
        allowToSelectDisabledItems: {
            type: Boolean,
            default: false,
        },
        needIcon: {
            type: Boolean,
            default: true,
        },
        needSearch: {
            type: Boolean,
            default: true,
        },
        isSmallDropdown: {
            type: Boolean,
            default: false,
        },
        defaultItemText: {
            type: String,
            default: 'Select Item',
        },
    },
    data() {
        return {
            showDropdown: false,
            uniqueId: null,
            assetsGroupsNames: {
                'crypto-spot': 'Crypto',
                'crypto-stable-coin': 'Stable Coin',
                Fiat: 'Fiat',
            },
            searchValue: '',
            firstGroupLoaded: false,
        };
    },
    computed: {
        currentUser() {
            return this.$store.state.User.currentUser;
        },
        validBeneficiaryNames() {
            return this.currentUser?.possibleNames ?? [];
        },
        filteredElements() {
            if (this.isAddresses) {
                return this.elements.filter((e, i) => (
                    this.addressAliases
                    && this.addressAliases.length > 0
                    && this.addressAliases[i]
                    && this.addressAliases[i].toLowerCase().indexOf(this.searchValue.toLowerCase()) !== -1
                ));
            }
            if (this.isRequisites && this.isEmpty) {
                return [];
            }
            return this.elements.filter((e) => e.toLowerCase().indexOf(this.searchValue.toLowerCase()) !== -1);
        },
        groupedElements() {
            if (this.assetsGroups?.length === 0) {
                return [];
            }
            let res: { type: string; assets: string[]; }[] = [];
            this.assetsGroups!.forEach((val) => {
                const index = res.findIndex((r) => r.type === val.type);
                if (index !== -1) {
                    res[index].assets.push(val.symbol);
                } else {
                    res.push({ type: val.type, assets: [val.symbol] });
                }
            });

            res = res.reduce((accum, current) => {
                if (current.assets.some((a) => a.toLowerCase().indexOf(this.searchValue.toLowerCase()) !== -1)) {
                    accum.push({
                        type: current.type,
                        assets: current.assets.filter((a) => a.toLowerCase().indexOf(this.searchValue.toLowerCase()) !== -1),
                    });
                }
                return accum;
            }, [] as { type: string; assets: string[]; }[]);

            return res.sort((a, b) => {
                if (a.type === 'fiat' || b.type === 'crypto-spot') {
                    return -1;
                }
                return 1;
            });
        },
    },
    methods: {
        setActiveElement(index) {
            if (this.disabledElementsIndexes?.includes(index) && !this.allowToSelectDisabledItems) {
                return;
            }
            this.showDropdown = false;
            this.$emit('select-element', index);
        },
        shortString(string) {
            if (!string) {
                return '...';
            }
            return `${string.substring(0, 4)}...${string.substring(string.length - 4, string.length)}`;
        },
        setGroupedElement(symbol: string) {
            this.setActiveElement(this.elements.findIndex((el) => el === symbol));
        },
        async goToRequisitesManagement() {
            this.$store.commit(SET_LAST_ROUTE('/wallets'));
            await this.$router.push({
                path: '/profile/addresses-management',
                query: {
                    variant: '1',
                    previousRoute: this.$router.currentRoute.path,
                },
            }).catch(() => { /* navigation error */ });
        },
    },
    mounted() {
        document.addEventListener('click', (event) => {
            const path = composedPath(event.target);
            if (!path.some((node) => node.classList && node.classList.contains('assets-dropdown') && node.id === this.uniqueId)) {
                this.showDropdown = false;
            }
        });
        this.uniqueId = nanoid(8);

        if (this.groupedElements.length) {
            if (this.activeItemIndex) {
                this.setGroupedElement(this.elements[this.activeItemIndex]);
            } else {
                this.setGroupedElement(this.groupedElements[0].assets[0]);
            }
        }
    },
    watch: {
        groupedElements() {
            if (!this.firstGroupLoaded && this.groupedElements.length) {
                this.firstGroupLoaded = true;
                if (this.activeItemIndex) {
                    this.setGroupedElement(this.elements[this.activeItemIndex]);
                } else {
                    this.setGroupedElement(this.groupedElements[0].assets[0]);
                }
            }
        },
        isEmpty(current) {
            if (current && this.activeItemIndex) {
                this.setActiveElement(0);
            }
        },
        showDropdown() {
            this.searchValue = '';
        },
    },
});
